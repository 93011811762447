<template>

  <div>

    <oficinas-filters @applyFilter="filterByPlace"/>


    <b-card>

      <b-table
          :items="items"
          :fields="fields"
      >

      </b-table>

    </b-card>
  </div>

</template>

<script>

import OficinasFilters from '@/modules/ejecutante/views/catalogos/oficinas/OficinasFilters.vue'

import { BCard,BTable } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'OficinasList',
  components: {
    OficinasFilters,
    BCard,
    BTable
  },
  data(){
    return {

      fields: [
        { key: 'offNombre', label: 'Nombre', sortable: true },
        { key: 'offDireccion', label: 'Dirección', sortable: true },
        { key: 'plaNombre', label: 'Ubicación', sortable: true },
      ],

      items: []

    }
  },

  computed:{
    ...mapGetters('sga-module', ['fetchOficinas']),


  },

  methods: {

    ...mapActions('sga-module', ['getOficinas']),

    filterByPlace( place ){

      this.items = this.fetchOficinas( place )

    },



  },

  async created() {

    await this.getOficinas()

  }

}
</script>

<style scoped>

</style>
