<template>

  <b-card>

    <b-card-header>
      <h5>Filtros</h5>
    </b-card-header>


    <b-card-body>

      <b-row>

        <b-col>


          <label>Ubicación</label>

          <v-select
              label="plaNombre"
              :options="optionsPlaces"
              :reduce="ubicacion => ubicacion.plaId"
              v-model="plaId"
              @input="changeData"
          />


        </b-col>

      </b-row>

    </b-card-body>

  </b-card>



</template>

<script>

import {
  BCard,
  BCardHeader,
  BCardBody,
    BRow,
    BCol
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OficinasFilters',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    vSelect
  },

  data() {

    return {
      plaId: null,
      optionsPlaces: []
    }

  },

  computed: {

    ...mapGetters('sga-module', ['fetchUbicaciones'])

  },

  methods: {

    ...mapActions('sga-module',['getUbicaciones']),

    // v-select| emitir evento de actualizacion cuando cambia la ubicacion seleccionada
    changeData(){
      this.$emit('applyFilter', this.plaId)
    }

  },

  // se ejecuta cuando se crea el componente
  async created() {

    // llamar API de ubicaciones y guardarla en STATE
    await this.getUbicaciones()

    // guardar las ubicaciones recibidas
    const ubicaciones = this.fetchUbicaciones
    this.optionsPlaces = ubicaciones

    // determinar primer elemento de todas las ubicaciones
    const [ defaultUbicacion ] = ubicaciones
    this.plaId = null

    // emitir evento para actualizar componente padre (oficinas-list)
    this.changeData()


  },

  mounted() {

  }

}
</script>

<style scoped>

</style>
