<template>

  <div>
    <oficinas-list/>
  </div>



</template>

<script>


import OficinasList from '@/modules/ejecutante/views/catalogos/oficinas/OficinasList.vue'

export default {
  name: 'Oficinas',
  components: {
    OficinasList
  }
}
</script>

<style scoped>

</style>
